<template lang="html">
  <v-dialog fullscreen persistent v-model="dialog">
    <div class="confirmation py-4">
      <PrintVersion ref="print" v-show="false" />
      <div
        :class="[
          'px-3 mb-0 pb-0',
          'font-weight-bold',
          { 'text-center': $vuetify.breakpoint.smAndDown },
          'text-h5',
        ]"
      >
        Seu agendamento foi realizado com sucesso!
      </div>
      <div class="font-weight-bold mt-8">Estamos aguardando por você!</div>
      <v-icon color="primary" class="my-8">mdi-check-circle-outline</v-icon>
      <v-btn
        color="primary"
        elevation="0"
        @click="$router.go()"
        :loading="loading"
        class="mb-4"
      >
        Realizar novo atendimento
      </v-btn>
      <v-btn color="primary" outlined elevation="0" class="mb-4" @click="print">
        Imprimir comprovante
      </v-btn>
      <img :src="organization.logo" alt="logo" />
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Tip from '@/views/schedule/widgets/tip'
import { isEmpty } from 'lodash'
import Recaptcha from '@/components/Recaptcha'
import PrintVersion from './print'

export default {
  name: 'ScheduleConfirmationStep',
  components: { Tip, Recaptcha, PrintVersion },
  data() {
    return {
      dialog: false,
      obs: '',
      done: false,
      loading: false,
      terms: false,
    }
  },

  mounted() {
    // this._loadData()
  },

  computed: {
    ...mapState({
      patient: state => state.schedule.form.patient,
      step: state => state.schedule.step,
      organization: state => state.data.organization,
      group: state => state.schedule.group,
      options: state => state.schedule.options,
      termosPortal: state => state.data.organization.termos_portal,
    }),

    ...mapGetters({
      hour: 'schedule/hour',
      covenant: 'schedule/covenant',
      plan: 'schedule/plan',
      manyProductsAllowed: 'schedule/manyProductsAllowed',
      branches: 'schedule/selectedBranches',
      shifts: 'schedule/selectedShifts',
      doctor: 'schedule/doctor',
    }),

    requiresTerms() {
      return this.termosPortal.id != null
    },

    appointment() {
      return this.$store.getters['schedule/selectedAppointment'] || {}
    },

    tip() {
      let tip = null
      if (this.group == 'exams') {
        tip = this.options.exam_schedule_confirmation_screen_tip
      }
      if (this.group == 'appointments') {
        tip = this.options.appointment_schedule_confirmation_screen_tip
      }

      return tip
    },

    doneTip() {
      let tip = null
      if (this.group == 'exams') {
        tip = this.options.exam_schedule_done_screen_tip
      }
      if (this.group == 'appointments') {
        tip = this.options.appointment_schedule_done_screen_tip
      }

      return tip
    },

    day() {
      return isEmpty(this.hour)
        ? ''
        : this.$moment(this.hour.time, 'YYYYMMDD HH:mm').format('DD')
    },

    month() {
      return isEmpty(this.hour)
        ? ''
        : this.$moment(this.hour.time, 'YYYYMMDD HH:mm').format('MMMM')
    },

    hourAndMinutes() {
      return isEmpty(this.hour)
        ? ''
        : this.$moment(this.hour.time, 'YYYYMMDD HH:mm').format('HH:mm')
    },

    dateAndHour() {
      return isEmpty(this.hour)
        ? ''
        : this.$moment(this.hour.time, 'YYYYMMDD HH:mm').format(
            'DD/MM/YYYY HH:mm'
          )
    },

    products() {
      return this.$store.getters['schedule/selectedProducts'].map(p => p.text)
    },

    examLabel() {
      return this.products.length > 1
        ? 'Exames selecionados'
        : 'Exame selecionado'
    },

    place() {
      return isEmpty(this.hour) ? {} : this.hour.place()
    },

    finishButtonTitle() {
      return this.done ? 'Voltar para tela inicial' : 'Confirmar'
    },
  },

  methods: {
    show() {
      this.dialog = true
    },

    print() {
      // Get HTML to print from element
      const prtHtml = this.$refs.print.$el.innerHTML

      // Get all stylesheets HTML
      const stylesHtml = `
          <style type="text/css" scoped>
            body {
              font-family: 'Avenir', Helvetica, Arial, sans-serif;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.87);
            }

            p {margin: 0px}

            .logo {
              text-align: center;
              margin-bottom: 1em;
            }

            .logo img{
              max-height: 40px;
              max-width: 100px;
              width: auto !important;
            }

            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 1em;
            }

            .box {
              border: 1px solid black;
              padding: 5px;
              margin-bottom: 1em;
            }

            .box .title {
              font-weight: bold;
              font-size: 1.2em;
              margin-bottom: 10px;
            }

            .page-title {
              text-align: center;
              font-size: 1.4em;
              font-weight: bold;
              margin-bottom: 1em;
            }

            .page-title .subtitle {
              font-weight: normal;
              font-size: 1em;
            }

            .info {
              margin-bottom: 10px;
            }

            .info .label:after{
              content: '\\00a0';
            }

            .info .content {
              font-weight: bold;
            }

            .branch, .shift {
              margin-bottom: 2px;
            }

            .address, .shift span{
              font-size: 0.8em;
              font-weight: normal;
            }

            .grid {
              display: flex;
            }

            .col {
              flex: 1;
            }
          </style>
        `

      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      )
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head></head>
          <body>
            ${stylesHtml}
            ${prtHtml}
          </body>
        </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background: #f4f4f8 !important;
  height: 100%;
  overflow-y: auto;

  img {
    max-height: 45px;
  }

  .v-icon {
    font-size: 300px;
  }
}
</style>
