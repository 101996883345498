<template>
  <div>
    <v-date-picker
      width="290"
      no-title
      v-model="form.date"
      class="place-data-picker my-6"
      :allowed-dates="allowedDates"
    ></v-date-picker>
    <div v-for="(doctor, doctorIndex) in availableDoctors" :key="doctorIndex">
      <div class="font-weight-bold primary--text">
        {{ doctor.name | DRname }}
      </div>
      <v-btn
        small
        v-for="(hour, hourIndex) in doctor.availableHours"
        :key="hourIndex"
        @click.prevent="setHour(hour.id)"
        class="ma-1 hour-btn"
        elevation="0"
        dark
        :class="[{ active: form.hour_id == hour.id }, 'hour']"
        color="primary"
      >
        {{ hour.time | hour }}
      </v-btn>
    </div>
    <!-- <div
      v-for="(schedule, scheduleIndex) in place.schedules"
      :key="scheduleIndex"
    >
      <div class="font-weight-bold primary--text">
        {{ schedule.doctor.name | DRname }}
      </div>
      <v-btn
        small
        v-for="(hour, hourIndex) in doctorHours(schedule.doctor)"
        :key="hourIndex"
        @click.prevent="setHour(hour.id)"
        class="ma-1 hour-btn"
        elevation="0"
        dark
        :class="[{ active: form.hour_id == hour.id }, 'hour']"
        color="primary"
      >
        {{ hour.time | hour }}
      </v-btn>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PlacePanelSchedule',

  filters: {
    DRname: function (value) {
      if (!value) return ''
      return value.toUpperCase().startsWith('DR') ? value : `Dr(a) ${value}`
    },

    hour: function (value) {
      if (!value) return ''
      return value.substr(value.length - 5)
    },
  },

  props: {
    hour_id: {
      type: Number,
      default: null,
    },
    place: {
      type: Object,
      default: () => ({
        id: null,
        schedules: [],
        // hours: [],
        // name: null,
      }),
    },
  },

  computed: {
    ...mapGetters({
      hours: 'schedule/hours',
    }),

    availableDoctors() {
      const doctors = []
      this.place.schedules.forEach(schedule => {
        const doctor = schedule.doctor
        const hours = this.doctorHours(doctor)
        if (hours.length > 0) {
          doctor.availableHours = this.doctorHours(doctor)
          doctors.push(doctor)
        }
      })

      return doctors
    },
  },

  // computed: {
  //   availableHours() {
  //     let hours = this.form.date
  //       ? this.doctor.hours.filter(
  //           h =>
  //             this.$moment(h.time, 'YYYYMMDD HH:mm').format('YYYY-MM-DD') ==
  //             this.form.date
  //         )
  //       : []

  //     // filter by hours greater than the current time.
  //     const currentDate = this.$moment(this.form.date, 'YYYY-MM-DD').format(
  //       'YYYYMMDD'
  //     )
  //     if (this.$moment(currentDate, 'YYYYMMDD').isSame(new Date(), 'day')) {
  //       const currentTime = this.$moment()
  //       hours = hours.filter(
  //         h => this.$moment(h.time, 'YYYYMMDD HH:mm') >= currentTime
  //       )
  //     }

  //     return hours
  //   },
  // },

  data() {
    return {
      loading: false,
      form: {
        date: null,
        hour_id: this.hour_id,
      },
    }
  },

  methods: {
    setHour(id) {
      this.form.hour_id = id
      this.$emit('updateHour', id)
    },

    allowedDates(val) {
      const datesMap = this.hours
        .filter(h => h.place_id === this.place.id)
        .map(x => this.$moment(x.time, 'YYYYMMDD HH:mm').format('YYYY-MM-DD'))
      const dates = [...new Set(datesMap)]
      return dates.includes(val)
    },

    doctorHours(doctor) {
      // console.log('doctor.hours', doctor.hours)
      // return []
      let hours = this.form.date
        ? doctor.hours.filter(
            h =>
              this.$moment(h.time, 'YYYYMMDD HH:mm').format('YYYY-MM-DD') ==
              this.form.date
          )
        : []

      // filter by hours greater than the current time.
      const currentDate = this.$moment(this.form.date, 'YYYY-MM-DD').format(
        'YYYYMMDD'
      )
      if (this.$moment(currentDate, 'YYYYMMDD').isSame(new Date(), 'day')) {
        const currentTime = this.$moment()
        hours = hours.filter(
          h => this.$moment(h.time, 'YYYYMMDD HH:mm') >= currentTime
        )
      }

      return hours
    },

    // allowedDates(val) {
    //   // console.log('allowedDates', val)
    //   const datesMap = this.doctor.hours.map(x =>
    //     this.$moment(x.time, 'YYYYMMDD HH:mm').format('YYYY-MM-DD')
    //   )
    //   const dates = [...new Set(datesMap)]
    //   return dates.includes(val)
    // },
  },
}
</script>
<style lang="scss" scoped>
.hour {
  opacity: 0.6;
}
.active.hour {
  opacity: 1;
}

.hour-btn {
  max-width: 36px;
}
</style>
