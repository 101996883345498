<template lang="html">
  <div>
    <div class="container">
      <div class="logo">
        <img alt="Logo" :src="organization.logo" />
      </div>
      <div class="page-title">
        AGENDAMENTO ONLINE
        <hr />
        <p v-if="tip" class="subtitle">{{ tip }}</p>
      </div>
      <div class="box">
        <p class="title">Dados do paciente</p>
        <div class="info">
          <p class="content">{{ form.patient.name }}</p>
        </div>
        <div class="info">
          <p class="label">E-mail</p>
          <p class="content">{{ form.patient.email }}</p>
        </div>
        <div class="grid">
          <div class="info col">
            <p class="label">CPF</p>
            <p class="content">{{ form.patient.cpf }}</p>
          </div>
          <div class="info col">
            <p class="label">Dt. Nascimento</p>
            <p class="content">{{ form.patient.birthday }}</p>
          </div>
          <div class="info col">
            <p class="label">Peso (kg)</p>
            <p class="content">{{ form.patient.weight }}</p>
          </div>
        </div>
        <div class="grid">
          <div class="info col">
            <p class="label">Contatos</p>
            <p class="content">{{ form.patient.phone1 }}</p>
          </div>
          <div class="info col">
            <p class="label"></p>
            <p class="content">{{ form.patient.phone2 }}</p>
          </div>
        </div>
      </div>
      <div v-if="form.exam.group == 'exams'" class="box">
        <div class="info">
          <p class="label">{{ examLabel }}</p>
          <div class="content">
            <div v-for="(product, index) in products" :key="index">
              <b>{{ product }}</b>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form.exam.group == 'appointments'" class="box">
        <div class="info">
          <p class="label">Especialidade selecionada</p>
          <div class="content">
            <div>
              <b>{{ appointment.NomeRecurso }}</b>
            </div>
          </div>
        </div>
      </div>
      <div v-if="doctor" class="box">
        <div class="info">
          <p class="label">Médico</p>
          <div class="content">
            <div>
              <b>{{ doctor.name }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="grid">
          <div class="col info">
            <p class="label">Convênio</p>
            <p class="content">{{ covenant.con_nomefant }}</p>
          </div>
          <div class="col info">
            <p class="label">Plano</p>
            <p class="content">{{ plan.plc_nome }}</p>
          </div>
        </div>
      </div>
      <div v-if="manyProductsAllowed" class="box">
        <div class="grid">
          <div class="col info">
            <p class="label">Dias e turnos de sua preferência</p>
            <div class="content">
              <div class="ui list">
                <div
                  v-for="(shiftKey, index) in Object.keys(shifts)"
                  :key="index"
                  class="shift"
                >
                  <b>{{ shiftKey }}</b
                  ><span v-if="shifts[shiftKey].length > 0">
                    - {{ shifts[shiftKey].join(', ') }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col info">
            <p class="label">Locais de sua preferência</p>
            <div class="content">
              <div
                v-for="(branche, index) in branches"
                :key="index"
                class="branch"
              >
                {{ branche.emp_nomfan }}
                <div v-if="branche.emp_end != null" class="address">
                  {{ branche.emp_end }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!manyProductsAllowed" class="box">
        <div class="grid">
          <div class="col info">
            <p class="label">Data / Hora</p>
            <p class="content">{{ dateAndHour }}</p>
          </div>
          <div class="col info">
            <p class="label">Local de Atendimento</p>
            <div class="content">
              <div class="branch">
                {{ place.name }}
                <div class="address">{{ place.address }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="info">
          <p class="label">Informação adicional do agendamento</p>
          <p class="content">{{ form.patient.obs }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      organization: state => state.data.organization,
      form: state => state.schedule.form,
      patient: state => state.schedule.form.patient,
      step: state => state.schedule.step,
      scheduleObs: state => state.schedule.form.obs,
      group: state => state.schedule.group,
      options: state => state.schedule.options,
    }),

    ...mapGetters({
      hour: 'schedule/hour',
      covenant: 'schedule/covenant',
      plan: 'schedule/plan',
      manyProductsAllowed: 'schedule/manyProductsAllowed',
      branches: 'schedule/selectedBranches',
      doctor: 'schedule/doctor',
      shifts: 'schedule/selectedShifts',
    }),

    tip() {
      let tip = null
      if (this.group == 'exams') {
        tip = this.options.exam_schedule_done_screen_tip
      }
      if (this.group == 'appointments') {
        tip = this.options.appointment_schedule_done_screen_tip
      }

      return tip
    },

    appointment() {
      return this.$store.getters['schedule/selectedAppointment'] || {}
    },

    dateAndHour() {
      return _.isEmpty(this.hour)
        ? ''
        : this.$moment(this.hour.time, 'YYYYMMDD HH:mm').format(
            'DD/MM/YYYY HH:mm'
          )
    },

    products() {
      return this.$store.getters['schedule/selectedProducts'].map(p => p.text)
    },

    examLabel() {
      return this.products.length > 1
        ? 'Exames selecionados'
        : 'Exame selecionado'
    },

    place() {
      return _.isEmpty(this.hour) ? {} : this.hour.place()
    },
  },

  methods: {
    print() {
      window.print()
    },
  },
}
</script>
