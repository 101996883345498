<template>
  <v-expansion-panel class="place-panel" elevation="0">
    <v-expansion-panel-header>
      <div class="place-panel-header">
        <v-btn
          color="primary"
          dark
          class="disable-events mr-4"
          fab
          elevation="0"
          x-small
        >
          <v-icon dark> mdi-map-marker-outline </v-icon>
        </v-btn>
        <div class="place-panel-header-title">
          <div class="font-weight-bold primary--text">{{ title }}</div>
          <div class="primary--text mt-2">{{ subtitle }}</div>
        </div>
        <v-icon color="primary" style="margin-left: auto">
          mdi-map-marker-outline
        </v-icon>
      </div>
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  name: 'PlacePanel',
  props: ['title', 'subtitle'],
}
</script>
<style lang="scss" scoped>
.place-panel {
  border-radius: 1rem !important;
  &::before {
    box-shadow: none;
  }

  &:not(:first-child)::after {
    border-top: none;
  }

  .place-panel-header {
    display: flex;
    align-items: center;
  }
}
</style>
