<template>
  <v-expansion-panels v-model="panel" elevation="0">
    <v-expansion-panel class="summary">
      <v-expansion-panel-header>
        <div class="summary-head">
          <div class="summary-head-title">
            <div class="font-weight-bold">Resumo</div>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form class="py-4" ref="form">
          <v-row v-show="!summary.appointment">
            <v-col>
              <SummaryField
                :label="summary.exams.length > 0 ? 'Exames' : 'Exame'"
                :value="summary.exams"
              />
            </v-col>
          </v-row>
          <v-row v-show="summary.appointment">
            <v-col>
              <SummaryField
                label="Especialidade"
                :value="summary.appointment"
              />
            </v-col>
            <v-col>
              <SummaryField label="Médico" :value="summary.doctor" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SummaryField label="Convênio" :value="summary.covenant" />
            </v-col>
            <v-col>
              <SummaryField label="Plano" :value="summary.plan" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row v-if="summary.exams.length < 2">
            <v-col>
              <SummaryField label="Data" :value="summary.date" />
            </v-col>
            <v-col>
              <SummaryField label="Hora" :value="summary.hour" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SummaryField
                label="Local"
                v-if="summary.places.length == 0"
                :value="summary.place"
              />
              <SummaryField label="Locais" v-else :value="summary.places" />
            </v-col>
          </v-row>
          <v-row v-show="summary.exams.length > 1">
            <v-col>
              <SummaryField label="Dia da Semana" :value="summary.daysOfWeek" />
            </v-col>
          </v-row>
          <v-row v-show="summary.exams.length > 1">
            <v-col>
              <SummaryField
                label="Turno do atendimento"
                :value="summary.shifts"
              />
            </v-col>
          </v-row>
        </v-form>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex'
import SummaryField from './summary_field'
export default {
  name: 'Summary',
  components: { SummaryField },
  computed: {
    ...mapGetters({
      summary: 'schedule/summary',
    }),
  },
  data() {
    return {
      panel: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
// .summary-panel {
//   border-radius: 1rem !important;
//   background: var(--box-bg-color);
//   &::before {
//     box-shadow: none;
//   }
// }
.summary {
  /* padding: 1rem; */
  // background: var(--box-bg-color);
  background-color: var(--box-bg-color) !important;
  border-radius: 1.375rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  font-size: 0.875rem;
  // min-height: 224px;
  padding: 5px;
  flex-direction: column;

  &::before {
    box-shadow: none;
  }

  &.v-expansion-panel--active {
    border-radius: 1.375rem !important;
  }

  &.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 52px;
  }

  &:not(:first-child)::after {
    border-top: none;
  }

  .v-expansion-panel-header {
    padding: 0px;
    background-color: white;
    border-radius: 1.375rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 0.875rem;
    text-align: center;
  }

  .content {
    padding: 1rem;
  }
  // .summary-head {
  //   display: flex;
  //   flex-direction: row;
  //   // background-color: white;
  //   height: 50px;
  //   width: 100%;
  // border-radius: 1.375rem;
  // border-bottom-left-radius: 0px;
  // border-bottom-right-radius: 0px;
  //   text-align: center;
  //   align-items: center;
  //   font-size: 0.875rem;
  // }

  .summary-body {
    padding: 1rem;
  }

  .summary-head-title {
    width: 100%;
  }
}
</style>
