class ScheduleRules {
  static PARAMS_MAP = {
    medico: 'doctorParam',
    grupo: 'group',
    convenio: 'covenant',
    plano: 'plan',
    recurso: 'appointment',
  }

  constructor(covenants, products, query = {}, callbacks = {}) {
    this.covenants = covenants
    this.products = products
    this.query = query
    this.callbacks = callbacks
    this.fields = {}

    this.queryErrors = []
    this.groups = [
      { name: 'exams', description: 'exame' },
      { name: 'appointments', description: 'consulta' },
    ]
  }

  _runCallback(name) {
    const callback = this.callbacks[name]
    if (typeof callback === 'function') {
      callback(this.fields)
    }
  }

  get group() {
    return this.groups.find(x => x.description === this.query.grupo)
  }

  get covenant() {
    if (this.group == null || this.query.convenio == null) {
      return null
    }

    return this.covenants[this.group.name].find(
      x => x.con_cod === this.query.convenio
    )
  }

  get resource() {
    const resourceParam = this.query.recurso
    const products = this.products[this.group.name]
    let product = null

    if (this.group.name == 'exams') {
      product = products.find(x => x.resource_value == resourceParam)
    }

    if (this.group.name == 'appointments') {
      product = products.find(x => x.CodRecurso == parseInt(resourceParam))
    }

    return product
  }

  get anyError() {
    return this.queryErrors.length > 0
  }

  pushError(errorMsg) {
    if (this.queryErrors.findIndex(x => x == errorMsg) < 0) {
      this.queryErrors.push(errorMsg)
    }
  }

  _checkGroupParam() {
    if (this.group == null) {
      this.pushError('Grupo não definido')
    } else {
      this.fields.group = this.group.name
    }
  }

  _checkCovenantParam() {
    this._checkGroupParam()
    if (this.anyError) {
      return
    }

    if (_.isEmpty(this.covenant)) {
      this.pushError('Convênio não encontrado')
    } else {
      this.fields.covenant_id = this.covenant.con_cod

      if (this.query.plano == null && this.covenant.planos.length == 1) {
        this.fields.plan_id = this.covenant.planos[0].plc_isn
      }
    }
  }

  _checkPlanParam() {
    if (this.anyError) {
      return
    }

    const planParam = parseInt(this.query.plano)
    if (planParam) {
      const plans = this.covenant == null ? [] : this.covenant.planos
      const plan = plans.find(x => x.plc_isn === planParam)

      if (plan == null) {
        this.pushError('Plano não encontrado')
      } else {
        this.fields.plan_id = plan.plc_isn
      }
    }
  }

  _checkResouceParam() {
    this._checkGroupParam()

    if (this.query.recurso != null && this.resource == null) {
      this.pushError('Recurso não encontrado')
      return
    }

    if (this.anyError) {
      return
    }

    if (this.resource) {
      if (this.group.name == 'appointments') {
        this.fields.appointment_id = this.resource.CodRecurso
      }

      if (this.group.name == 'exams') {
        // resource_text:"RESSONÂNCIA"
        // resource_value:"01003"
        // text:"RM ARTICULAR (POR ARTICULAçãO)"
        // value:"00039012"

        this.fields.exams = [this.resource]
      }
    }
  }

  _checkDoctorParam() {
    this._checkGroupParam()
    if (this.anyError) {
      return
    }

    const doctorParam = parseInt(this.query.medico)
    if (doctorParam) {
      this.fields.doctorParam = doctorParam

      if (this.resource != null) {
        const doctors = this.resource.Medicos || []
        const doctor = doctors.find(x => x.CodigoNaja == doctorParam)

        if (doctor == null) {
          this.pushError('Médico não encontrado')
        }
      }
    }
  }

  check() {
    if (this.query.grupo != null) {
      this._checkGroupParam()
    }

    if (this.query.convenio != null) {
      this._checkCovenantParam()
    }

    if (this.query.plano != null) {
      this._checkPlanParam()
    }

    if (this.query.recurso != null) {
      this._checkResouceParam()
    }

    if (this.query.medico != null) {
      this._checkDoctorParam()
    }
  }
}

export default ScheduleRules
