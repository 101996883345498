<template>
  <div>
    <div
      :class="[
        'px-3 mb-0 pb-0',
        'font-weight-bold',
        { 'text-center': $vuetify.breakpoint.smAndDown },
        'text-h5',
      ]"
    >
      Agendamento Online
    </div>
    <LoadingContainer v-show="loading" />
    <v-alert
      text
      outlined
      color="deep-orange"
      icon="mdi-alert-circle"
      v-for="(erroMsg, index) in queryErrors"
      :key="index"
      v-show="!loading"
      class="mt-8"
    >
      {{ erroMsg }}
    </v-alert>
    <div v-show="!loading && queryErrors.length == 0">
      <ProcedureStep ref="procedure" v-show="step == 1" />
      <DateStep ref="date" v-show="step == 2" />
      <PatientStep ref="patient" v-show="step == 3" />
      <ConfirmationStep ref="confirmation" v-show="step == 4" />
    </div>
  </div>
</template>

<script>
const SLUG_BASE_HOST = 'minhaagenda'
import ProcedureStep from './steps/procedure'
import DateStep from './steps/date'
import PatientStep from './steps/patient'
import ConfirmationStep from './steps/confirmation'
import LoadingContainer from '@/components/LoadingContainer'
import ScheduleRules from '@/helpers/ScheduleRules'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Schedule',
  components: {
    ProcedureStep,
    DateStep,
    PatientStep,
    LoadingContainer,
    ConfirmationStep,
  },

  mounted() {
    this._loadData()
  },

  data() {
    return {
      loading: false,
      queryErrors: [],
    }
  },

  computed: {
    ...mapGetters({
      manyProductsAllowed: 'schedule/manyProductsAllowed',
    }),

    ...mapState({
      options: state => state.schedule.options,
      step: state => state.schedule.step,
    }),
  },

  methods: {
    async _checkParams(query = null) {
      const scheduleRules = new ScheduleRules(
        this.$store.state.schedule.covenants,
        this.$store.state.schedule.products,
        query || this.$route.query
      )

      scheduleRules.check()

      if (scheduleRules.anyError) {
        this.queryErrors = scheduleRules.queryErrors
      } else {
        const scheduleFieldsKeys = Object.keys(scheduleRules.fields)

        const skipProcedureStep =
          ['group', 'covenant_id', 'plan_id', 'doctorParam'].every(v =>
            scheduleFieldsKeys.includes(v)
          ) &&
          (scheduleFieldsKeys.includes('appointment_id') ||
            scheduleFieldsKeys.includes('exams'))

        const examForm = {
          covenant_id: scheduleRules.fields.covenant_id,
          plan_id: scheduleRules.fields.plan_id,
          products:
            scheduleRules.fields.group == 'exams'
              ? scheduleRules.products.exams
                  .filter(x => x.value == query.produto)
                  .map(x => x.value)
              : [],
          appointment:
            scheduleRules.fields.group == 'appointments'
              ? scheduleRules.fields.appointment_id
              : null,
          doctor_id: scheduleRules.fields.doctorParam,
          group: scheduleRules.fields.group,
        }

        if (skipProcedureStep) {
          this.$store.commit('schedule/group', scheduleRules.fields.group)
          this.$store.commit('schedule/examForm', examForm)
          this.$store.commit('schedule/step', 2)

          await this.$store.dispatch('schedule/createOnlineSchedule')
          await this.$store.dispatch('schedule/getBranches')

          if (!this.manyProductsAllowed) {
            await this.$store.dispatch('schedule/recursiveGetSchedules')
          }

          return
        }

        this.$store.commit('schedule/examForm', {
          doctor_id: scheduleRules.fields.doctorParam,
        })

        scheduleRules.fields.doctor_id = scheduleRules.fields.doctorParam
        this.$refs.procedure.setForm(scheduleRules.fields)
      }
    },

    _isSlug() {
      return (
        this.$route.name == 'slug' &&
        window.location.hostname.includes(SLUG_BASE_HOST)
      )
    },

    _slugParams() {
      if (this._isSlug()) {
        const organizationSlugs =
          this.$store.state.data.organization.slugs || []
        const slugParam = this.$route.params.slug
        const slug = organizationSlugs.find(x => x.slug === slugParam)
        return slug.params
      }

      return null
    },

    _checkSlug() {
      const slugParams = this._slugParams()
      if (this._isSlug() && slugParams == null) {
        this.$router.push({ path: '/404' })
      } else {
        this._checkParams(slugParams)
      }

      // if (this._isSlug()) {
      //   const organizationSlugs =
      //     this.$store.state.data.organization.slugs || []
      //   const slugParam = this.$route.params.slug
      //   const slug = organizationSlugs.find(x => x.slug === slugParam)
      //   if (slug == null) {
      //     this.$router.push({ path: '/404' })
      //   } else {
      //     this._checkParams(slug.params)
      //   }
      // } else {
      //   this.$router.push({ path: '/404' })
      // }
    },

    async _loadData() {
      this.loading = true

      try {
        const slugParams = this._slugParams()
        const covenantsQuery = {}

        if (slugParams?.medico != null) {
          covenantsQuery.doctors = [slugParams.medico]
        }

        await this.$store.dispatch('getOrganization')
        await this.$store.dispatch('schedule/getCovenants', covenantsQuery)
        await this.$store.dispatch('schedule/getOptions')

        if (this.options.exam_schedule_enabled) {
          await this.$store.dispatch('schedule/getProducts')
          await this.$store.dispatch('schedule/getExamScheduleResources')
        }

        if (this.options.appointment_schedule_enabled) {
          await this.$store.dispatch('schedule/getScheduleResources')
        }

        // set default group
        if (
          !this.options.exam_schedule_enabled &&
          this.options.appointment_schedule_enabled
        ) {
          this.$refs.procedure.setGroup('appointments')
        }

        this._checkSlug()
      } catch (e) {
        this.$toast.show('Erro ao carregar dados', 'warn')
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.schedule {
  background: white;
  padding: 2rem 3rem;
  border-radius: 1.5rem 1.5rem 0px 0px;
  height: 100%;
}
</style>
