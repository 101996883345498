<template>
  <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey"></div>
</template>
<script>
import $ from 'jquery'
export default {
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
      widgetId: 0,
    }
  },

  mounted() {
    this.render()
  },

  methods: {
    execute() {
      window.grecaptcha.execute(this.widgetId)
    },

    reset() {
      window.grecaptcha.reset(this.widgetId)
    },

    render() {
      setTimeout(() => {
        if (window.grecaptcha.render != undefined) {
          this.widgetId = window.grecaptcha.render('g-recaptcha', {
            sitekey: this.sitekey,
            size: 'invisible',
            callback: response => {
              this.$emit('verify', response)
              this.reset()
            },
          })

          $(this.$el).find('.grecaptcha-badge').css({
            top: '50%',
            bottom: '50%',
          })
        } else {
          this.render()
        }
      }, 1000)
    },
  },
}
</script>
