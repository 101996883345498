<template>
  <div class="summary-field">
    <div class="summary-label">{{ label }}</div>
    <div v-if="multiple" class="summary-value">
      <div class="mb-2" v-for="(item, index) in value" :key="index">
        {{ item }}
      </div>
    </div>
    <div v-else class="summary-value">{{ value }}</div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'SummaryField',
  props: ['label', 'value'],
  computed: {
    multiple() {
      return Array.isArray(this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-field {
  .summary-label {
    margin-bottom: 0.625rem;
  }

  .summary-value {
    background-color: white;
    border-radius: 0.5rem;
    min-height: 2rem;
    padding: 0.75rem;
  }
}
</style>
