<template lang="html">
  <div class="schedule-procedure">
    <div class="main-content">
      <v-row no-gutters>
        <v-col cols="12" class="pa-3">
          <div
            :class="[
              'font-weight-bold',
              { 'text-center': $vuetify.breakpoint.smAndDown },
              'text-h6',
            ]"
          >
            Agora vamos selecionar o procedimento e o convênio
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="order-last order-sm-first" sm="8">
          <v-form ref="form" class="box pa-8" v-model="form.valid">
            <div class="form-content">
              <v-row>
                <v-col cols="12" sm="6" class="order-last order-sm-first">
                  <div>
                    <div
                      :class="[
                        'font-weight-bold',
                        'mb-2 mt-4',
                        { 'text-center': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      Selecione o procedimento:
                    </div>
                    <div class="d-flex justify-center justify-sm-start">
                      <v-btn
                        v-if="options.exam_schedule_enabled"
                        color="primary"
                        :outlined="form.group != 'exams'"
                        elevation="0"
                        class="mr-4"
                        @click="form.group = 'exams'"
                      >
                        Exame
                      </v-btn>
                      <v-btn
                        v-if="options.appointment_schedule_enabled"
                        color="primary"
                        :outlined="form.group != 'appointments'"
                        elevation="0"
                        @click="form.group = 'appointments'"
                      >
                        Consulta
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <Breadcrumbs
                    :current="0"
                    class="justify-center justify-sm-end"
                  />
                </v-col>
              </v-row>

              <!-- only exams -->
              <v-row v-show="form.group == 'exams'">
                <v-col>
                  <v-select
                    label="Tipo de exame"
                    background-color="#fff"
                    filled
                    outlined
                    dense
                    :rules="rules.examGroup"
                    v-model="examGroup"
                    :items="examGroups"
                    :hint="
                      multipleExamAllowed
                        ? 'Você pode selecionar um ou mais exames'
                        : null
                    "
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" v-if="multipleExamAllowed">
                  <v-autocomplete
                    outlined
                    label="Digite o exame desejado"
                    append-icon="mdi-magnify"
                    dense
                    background-color="#fff"
                    ref="examsSearch"
                    :rules="rules.exams"
                    @change="onChangeExam"
                    :items="currentExams"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col v-else cols="12" sm="6">
                  <v-select
                    label="Digite o exame desejado"
                    ref="examSearch"
                    :rules="rules.exams"
                    filled
                    outlined
                    dense
                    return-object
                    background-color="#fff"
                    @change="exam => (form.exams = [exam])"
                    :items="currentExams"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- multiple exams -->
              <v-row
                class="exam-list"
                v-show="form.group == 'exams' && multipleExamAllowed"
              >
                <v-col
                  cols="12"
                  class="exam-item"
                  v-for="(exam, index) in this.form.exams"
                  :key="`exam-${index}`"
                >
                  <div class="exam-title">
                    <v-btn
                      class="disable-events"
                      dark
                      color="primary"
                      x-small
                      fab
                      elevation="0"
                    >
                      <v-icon> mdi-heart-pulse </v-icon>
                    </v-btn>
                    <div class="ml-2">
                      {{ exam.text }}
                    </div>
                  </div>

                  <v-btn
                    icon
                    small
                    color="primary"
                    class="v-alert__dismissible"
                    @click="form.exams.splice(index, 1)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- only appointments -->
              <v-row v-show="form.group == 'appointments'">
                <v-col>
                  <v-select
                    label="Selecione uma especialidade"
                    background-color="#fff"
                    filled
                    outlined
                    dense
                    :rules="rules.appointment_id"
                    v-model="form.appointment_id"
                    :items="appointments"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    label="Selecione um médico"
                    background-color="#fff"
                    filled
                    outlined
                    dense
                    v-model="form.doctor_id"
                    :disabled="form.doctorParam != null"
                    :items="doctors"
                    item-value="CodigoNaja"
                    item-text="NOME"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- all -->
              <v-row no-gutters>
                <v-col>
                  <v-divider />
                </v-col>
              </v-row>

              <div
                :class="[
                  'font-weight-bold',
                  'mb-2 mt-4',
                  { 'text-center': $vuetify.breakpoint.smAndDown },
                ]"
              >
                Selecione o convênio e o plano:
              </div>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    dense
                    :items="covenantOptions"
                    label="Convênio"
                    background-color="#fff"
                    v-model="form.covenant_id"
                    :rules="rules.covenant_id"
                    filled
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    dense
                    :items="currentPlans"
                    v-model="form.plan_id"
                    :rules="rules.plan_id"
                    label="Plano"
                    background-color="#fff"
                    filled
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </div>

            <div class="form-footer">
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    small
                    color="primary"
                    elevation="0"
                    @click.prevent="commit"
                    :loading="loading"
                  >
                    Próximo
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-col>
        <v-col>
          <Tip v-if="tip" class="mb-4">
            <div v-html="tip"></div>
          </Tip>
          <Summary />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Tip from '@/views/schedule/widgets/tip'
import Summary from '@/views/schedule/widgets/summary'
import Breadcrumbs from '@/views/schedule/widgets/breadcrumbs'
import { isEmpty } from 'lodash'

export default {
  name: 'ScheduleProcedureStep',
  components: { Tip, Summary, Breadcrumbs },
  data() {
    return {
      loading: false,
      examGroup: null,
      skipCovenantWath: false,
      rules: {
        examGroup: [
          v => {
            if (this.form.group == 'exams' && this.submitted) {
              return !!v || 'Selecione um tipo de exame'
            }
            return true
          },
        ],
        exams: [
          () => {
            if (this.form.group == 'exams') {
              return this.form.exams.length > 0 || 'Selecione um exame'
            }
            return true
          },
        ],
        appointment_id: [
          () => {
            if (this.form.group == 'appointments') {
              return !!this.form.appointment_id || 'Selecione uma consulta'
            }
            return true
          },
        ],
        covenant_id: [v => !!v || 'Selecione um convênio'],
        plan_id: [v => !!v || 'Selecione um plano'],
      },
      submitted: false,
      form: {
        valid: true,
        exams: [],
        covenant_id: null,
        plan_id: null,
        doctor_id: null,
        group: 'exams',
        appointment_id: null,
        doctorParam: null,
      },
    }
  },

  mounted() {
    // this._loadData()
  },

  computed: {
    ...mapGetters({
      manyProductsAllowed: 'schedule/manyProductsAllowed',
    }),

    ...mapState({
      options: state => state.schedule.options,
      exams: state => state.schedule.products.exams,
    }),

    multipleExamAllowed() {
      return this.options.exam_schedule_multiple_allowed == 'true'
    },

    examGroups() {
      const examGroups = this.exams.map(exam => {
        return {
          value: exam.resource_value,
          text: exam.resource_text,
        }
      })

      const uniqueByValue = [
        ...new Map(examGroups.map(item => [item['value'], item])).values(),
      ]

      return uniqueByValue
    },

    currentExams() {
      const examGroup = this.examGroups.find(e => e.value === this.examGroup)
      if (examGroup == null) {
        return []
      }

      return this.exams.filter(e => e.resource_value == this.examGroup)
    },

    tip() {
      let tip = null
      if (this.form.group == 'exams') {
        tip = this.options.exam_schedule_procedure_screen_tip
      }
      if (this.form.group == 'appointments') {
        tip = this.options.appointment_schedule_procedure_screen_tip
      }

      return tip
    },

    covenants() {
      return this.$store.state.schedule.covenants[this.form.group] || []
    },

    covenantOptions() {
      return this.covenants.map(covenant => {
        return {
          text: covenant.con_nomefant,
          value: covenant.con_cod,
        }
      })
    },

    currentPlans() {
      const covenant =
        this.covenants.find(c => c.con_cod === this.form.covenant_id) || {}
      const plans = covenant.planos || []
      return plans
        .sort((a, b) => a['plc_nome'].localeCompare(b['plc_nome']))
        .map(plan => {
          return {
            text: plan.plc_nome,
            value: plan.plc_isn,
          }
        })
    },

    appointments() {
      const data = this.$store.state.schedule.products.appointments
      const appointments =
        this.form.doctorParam == null
          ? data
          : data.filter(appointment =>
              appointment.Medicos.map(doctor => doctor.CodigoNaja).includes(
                this.form.doctorParam
              )
            )
      return appointments.map(appointment => {
        return {
          value: appointment.CodRecurso,
          text: appointment.NomeRecurso,
        }
      })
    },

    doctors() {
      const appointments = this.$store.state.schedule.products.appointments
      return this.form.appointment_id
        ? appointments.find(a => a.CodRecurso == this.form.appointment_id)
            ?.Medicos || []
        : []
    },

    // appointmentOptions() {
    //   return this.appointments.map(appointment => {
    //     return {
    //       value: appointment.CodRecurso,
    //       text: appointment.NomeRecurso,
    //     }
    //   })
    // },
  },

  watch: {
    'form.covenant_id': function () {
      if (!this.skipCovenantWath) {
        this.form.plan_id =
          this.currentPlans.length == 1 ? this.currentPlans[0].value : null
      }
    },
  },

  methods: {
    setGroup(group) {
      this.form.group = group
    },

    setForm(form) {
      this.skipCovenantWath = true
      this.form = Object.assign(this.form, form)

      setTimeout(() => {
        this.skipCovenantWath = false
      }, 10)
    },

    onChangeExam(exam) {
      if (this.form.exams.indexOf(exam) == -1) {
        this.form.exams.push(exam)
      }

      setTimeout(() => {
        this.$refs.examsSearch.reset()
      }, 100)
    },

    async commit() {
      this.submitted = true
      this.$refs.form.validate()
      await this.$sleep(100)

      if (this.form.valid) {
        try {
          this.loading = true
          const examForm = {
            covenant_id: this.form.covenant_id,
            plan_id: this.form.plan_id,
            group: this.form.group,
            products:
              this.form.group == 'exams'
                ? this.form.exams.map(x => x.value)
                : [],
            appointment:
              this.form.group == 'appointments'
                ? this.form.appointment_id
                : null,
            doctor_id:
              this.form.group == 'appointments' ? this.form.doctor_id : null,
          }

          this.$store.commit('schedule/group', this.form.group)
          this.$store.commit('schedule/examForm', examForm)
          this.$store.commit('schedule/step', 2)

          await this.$store.dispatch('schedule/createOnlineSchedule')
          await this.$store.dispatch('schedule/getBranches')

          if (!this.manyProductsAllowed) {
            await this.$store.dispatch('schedule/recursiveGetSchedules')
          }
        } catch (e) {
          this.$toast.show('Erro ao buscar dados', 'warn')
          throw e
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-form {
  min-height: 500px;
  display: flex;
  width: 100%;
  flex-direction: column;

  .form-content {
    flex-grow: 1;
  }
}

.exam-list {
  .exam-item {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0.75rem;
    border-radius: 1rem;
    justify-content: space-around;
    margin-bottom: 1rem;

    .exam-title {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
