<template lang="html">
  <div class="schedule-procedure">
    <div class="main-content">
      <v-row no-gutters>
        <v-col cols="12" class="pa-3">
          <div
            :class="[
              'font-weight-bold',
              { 'text-center': $vuetify.breakpoint.smAndDown },
              'text-h6',
            ]"
          >
            Excelente, nessa etapa vamos selecionar a data e o local!
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" class="order-last order-sm-first" sm="8">
        <v-form ref="form" class="box pa-8">
          <div class="form-content" v-if="manyProductsAllowed">
            <v-row>
              <v-col cols="12">
                <Breadcrumbs
                  :current="1"
                  class="justify-center justify-sm-end"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  :class="[
                    'font-weight-bold',
                    'mb-2 mt-4',
                    { 'text-center': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  Selecione os dias e turnos de sua preferência:
                </div>
              </v-col>
            </v-row>
            <v-row
              v-for="(day, dayIndex) in daysOfWeek"
              :key="dayIndex"
              no-gutters
            >
              <v-col cols="12" sm="3">
                <v-btn
                  :color="dayAdded(day) ? 'primary' : 'default'"
                  small
                  text
                  class="ma-1 disable-events"
                  elevation="0"
                >
                  {{ day }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="9">
                <v-btn
                  v-for="(shift, shiftIndex) in shifts(day)"
                  :key="shiftIndex"
                  @click.prevent="addShift(`${day}-${shift}`)"
                  color="primary"
                  small
                  :class="[
                    { active: form.shifts.includes(`${day}-${shift}`) },
                    'ma-1',
                    'hour',
                  ]"
                  elevation="0"
                >
                  {{ shift }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  :class="[
                    'font-weight-bold',
                    'mb-2 mt-4',
                    { 'text-center': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  Selecione um local de sua preferência:
                </div>
              </v-col>
            </v-row>
            <div class="places">
              <div
                v-for="(place, placeIndex) in places"
                :key="placeIndex"
                :class="[
                  { active: form.places.includes(place.emp_cod) },
                  'place-item mb-2',
                ]"
                @click="togglePlace(place.emp_cod)"
              >
                <div class="place-item-icon">
                  <v-btn
                    class="disable-events"
                    dark
                    color="primary"
                    x-small
                    fab
                    elevation="0"
                  >
                    <v-icon> mdi-map-marker </v-icon>
                  </v-btn>
                </div>
                <div class="place-item-body">
                  <div class="place-item-title font-weight-bold">
                    {{ place.emp_nomfan }}
                  </div>
                  <div class="place-item-subtile">{{ place.emp_end }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-content" v-else>
            <v-row>
              <v-col cols="12" sm="6" class="order-last order-sm-first">
                <div
                  :class="[
                    'font-weight-bold',
                    'mb-2 mt-4',
                    { 'text-center': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  Agendar por:
                </div>
                <div class="d-flex justify-center justify-sm-start">
                  <v-btn
                    color="primary"
                    :outlined="form.kind != 'date'"
                    elevation="0"
                    class="mr-4"
                    @click="form.kind = 'date'"
                  >
                    Data
                  </v-btn>
                  <v-btn
                    color="primary"
                    :outlined="form.kind != 'place'"
                    elevation="0"
                    @click="form.kind = 'place'"
                  >
                    Unidade
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <Breadcrumbs
                  :current="1"
                  class="justify-center justify-sm-end"
                />
              </v-col>
            </v-row>
            <!-- date -->
            <v-row v-show="form.kind == 'date'">
              <v-col cols="12">
                <div
                  :class="[
                    'font-weight-bold',
                    'mb-2 mt-4',
                    { 'text-center': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  Selecione o dia:
                </div>
                <v-date-picker
                  width="290"
                  no-title
                  v-model="form.date"
                  @update:picker-date="onChangeMonth"
                  color="primary"
                  :allowed-dates="allowedDates"
                >
                  <div
                    v-if="getSchedulesLoading"
                    style="
                      position: absolute;
                      top: 50%;
                      right: 50%;
                      bottom: 50%;
                    "
                  >
                    <v-row align="center" justify="center" class="my-4">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </div>
                </v-date-picker>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <v-expansion-panels :value="0">
                  <PlacePanel
                    v-for="(place, placeIndex) in availablePlaces"
                    :key="placeIndex"
                    :title="place.name"
                    :subtitle="place.address"
                  >
                    <div
                      v-for="(doctor, doctorIndex) in findDoctors(place.id)"
                      :key="doctorIndex"
                    >
                      <div class="font-weight-bold primary--text">
                        {{ doctor.name | DRname }}
                      </div>
                      <v-btn
                        small
                        v-for="(hour, hourIndex) in findHours(
                          doctor.id,
                          place.id
                        )"
                        :key="hourIndex"
                        @click.prevent="form.hour_id = hour.id"
                        class="ma-1 hour-btn"
                        elevation="0"
                        dark
                        :class="[{ active: form.hour_id == hour.id }, 'hour']"
                        color="primary"
                      >
                        {{ hour.time | hour }}
                      </v-btn>
                    </div>
                  </PlacePanel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <!-- place -->
            <v-row v-show="form.kind == 'place'">
              <v-col cols="12">
                <div
                  :class="[
                    'font-weight-bold',
                    'mb-2 mt-4',
                    { 'text-center': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  Selecione a unidade:
                </div>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels :value="0">
                  <PlacePanel
                    v-for="(place, placeIndex) in schedulePlaces"
                    :key="placeIndex"
                    :title="place.name"
                    :subtitle="place.address"
                  >
                    <PlacePanelSchedule
                      :hour_id="form.hour_id"
                      :place="place"
                      @updateHour="hour => (form.hour_id = hour)"
                    ></PlacePanelSchedule>
                  </PlacePanel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </div>

          <div class="form-footer mt-6">
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  outlined
                  elevation="0"
                  class="mr-4"
                  v-if="!doctorParam"
                  @click.prevent="$store.commit('schedule/step', 1)"
                >
                  Voltar
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  @click.prevent="commit"
                  :loading="loading"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-col>
      <v-col>
        <Tip v-if="tip" class="mb-4">
          <div v-html="tip"></div>
        </Tip>
        <Summary />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Tip from '@/views/schedule/widgets/tip'
import Summary from '@/views/schedule/widgets/summary'
import Breadcrumbs from '@/views/schedule/widgets/breadcrumbs'
import PlacePanel from './place_panel'
import PlacePanelSchedule from './place_panel_schedule'

export default {
  name: 'ScheduleDateStep',
  components: {
    Tip,
    Summary,
    Breadcrumbs,
    PlacePanel,
    PlacePanelSchedule,
  },
  data() {
    return {
      loading: false,
      getSchedulesLoading: false,
      form: {
        kind: 'date',
        date: null,
        hour_id: null,
        place_id: null,
        shifts: [],
        days: [],
        places: [],
      },
    }
  },

  filters: {
    DRname: function (value) {
      if (!value) return ''
      return value.toUpperCase().startsWith('DR') ? value : `Dr(a) ${value}`
    },

    hour: function (value) {
      if (!value) return ''
      return value.substr(value.length - 5)
    },
  },

  computed: {
    ...mapState({
      options: state => state.schedule.options,
      products: state => state.schedule.products,
      places: state => state.schedule.branches,
      schedulePlaces: state => state.schedule.places,
      group: state => state.schedule.group,
      doctorParam: state => state.schedule.form.exam.doctorParam,
      step: state => state.schedule.step,
    }),

    daysOfWeek() {
      const sorter = {
        Domingo: 0,
        'Segunda-feira': 1,
        'Terça-feira': 2,
        'Quarta-feira': 3,
        'Quinta-feira': 4,
        'Sexta-feira': 5,
        Sábado: 6,
      }

      const selectedResources = this.$store.getters[
        'schedule/selectedProducts'
      ].map(s => s.resource_value)
      const examScheduleShifts =
        this.$store.state.schedule.options.exam_schedule_shifts.filter(x =>
          selectedResources.includes(x.product_id)
        )

      const days = [...new Set(examScheduleShifts.map(s => s.day))]
      days.sort((a, b) => sorter[a] - sorter[b])
      return days
    },

    ...mapGetters({
      manyProductsAllowed: 'schedule/manyProductsAllowed',
      hours: 'schedule/hours',
    }),

    tip() {
      let tip = null
      if (this.group == 'exams') {
        tip = this.options.exam_schedule_date_screen_tip
      }
      if (this.group == 'appointments') {
        tip = this.options.appointment_schedule_date_screen_tip
      }

      return tip
    },

    availableHours() {
      const hours = this.$store.getters['schedule/hours']
      let availableDateHours = []
      if (this.form.date == null) {
        return []
      }

      const currentDate = this.$moment(this.form.date, 'YYYY-MM-DD').format(
        'YYYYMMDD'
      )

      availableDateHours = hours.filter(
        h =>
          this.$moment(h.time, 'YYYYMMDD HH:mm').format('YYYYMMDD') ==
          currentDate
      )

      if (this.$moment(currentDate, 'YYYYMMDD').isSame(new Date(), 'day')) {
        let currentTime = this.$moment()
        availableDateHours = availableDateHours.filter(
          h => this.$moment(h.time, 'YYYYMMDD HH:mm') >= currentTime
        )
      }

      return availableDateHours
    },

    availablePlaces() {
      const places = []
      const hours = this.availableHours

      hours.forEach(hour => {
        if (places.findIndex(p => p.id == hour.place_id) < 0) {
          let place = this.$store.getters['schedule/places'].find(
            p => p.id === hour.place_id
          )
          places.push(place)
        }
      })

      return places
    },
  },

  methods: {
    async onChangeMonth(month) {
      if (this.step === 2) {
        this.getSchedulesLoading = true
        const today = this.$moment()
        const startDate =
          today.format('YYYY-MM') === month
            ? today
            : this.$moment(`${month}-01`, 'YYYY-MM-DD')
        await this.$store.dispatch('schedule/getSchedules', startDate)
        this.getSchedulesLoading = false
      }
    },

    togglePlace(placeId) {
      let index = this.form.places.indexOf(placeId)
      if (index < 0) {
        this.form.places.push(placeId)
      } else {
        this.$delete(this.form.places, index)
      }
    },

    shifts(day = null) {
      const selectedResources = this.$store.getters[
        'schedule/selectedProducts'
      ].map(s => s.resource_value)
      const shifts =
        this.$store.state.schedule.options.exam_schedule_shifts.filter(x =>
          selectedResources.includes(x.product_id)
        )
      return day == null
        ? [...new Set(shifts.map(s => s.shift))]
        : [...new Set(shifts.filter(s => s.day == day).map(s => s.shift))]
    },

    addShift(shift) {
      const index = this.form.shifts.indexOf(shift)
      if (index < 0) {
        this.form.shifts.push(shift)
      } else {
        this.$delete(this.form.shifts, index)
      }
    },

    addDay(day) {
      const index = this.form.days.indexOf(day)
      if (index < 0) {
        this.form.days.push(day)
      } else {
        this.$delete(this.form.days, index)
      }
    },

    dayAdded(day) {
      return this.form.shifts.filter(s => s.includes(day)).length > 0
    },

    shiftAdded(shift) {
      return this.form.shifts.filter(s => s.includes(shift)).length > 0
    },

    allowedDates(val) {
      const datesMap = this.hours.map(x =>
        this.$moment(x.time, 'YYYYMMDD HH:mm').format('YYYY-MM-DD')
      )
      const dates = [...new Set(datesMap)]
      return dates.includes(val)
    },

    findDoctors(placeId) {
      const doctors = []
      const hours = this.availableHours.filter(h => h.place_id == placeId)

      hours.forEach(hour => {
        if (doctors.findIndex(d => d.id == hour.doctor_id) < 0) {
          let doctor = this.$store.getters['schedule/doctors'].find(
            d => d.id === hour.doctor_id
          )
          doctors.push(doctor)
        }
      })

      return doctors
    },

    findHours(doctorId, placeId = null) {
      placeId = placeId || this.form.place_id
      const hours = this.availableHours.filter(
        h => h.doctor_id == doctorId && h.place_id == placeId
      )

      return hours
    },

    commit() {
      const errors = []
      let payload = null
      if (this.manyProductsAllowed) {
        const places = this.form.places
        const shifts = []

        this.form.shifts.forEach(shift => {
          shifts.push(shift)
        })

        payload = {
          shifts: shifts,
          places: places,
        }

        if (payload.shifts.length < 1) {
          errors.push('Selecione pelo menos um turno')
        }
        if (payload.places.length < 1) {
          errors.push('Selecione pelo menos um local de atendimento')
        }
      } else {
        payload = {
          kind: this.form.kind,
          date: this.form.date,
          hour_id: this.form.hour_id,
          place_id: this.form.place_id,
          shifts: [],
          places: [],
        }

        if (payload.hour_id == null) {
          errors.push('Selecione um horário')
        }
      }

      if (errors.length == 0) {
        this.$store.commit('schedule/dateForm', payload)
        this.$store.commit('schedule/step', 3)
      } else {
        errors.forEach(errorMsg => {
          this.$toast.show(errorMsg, 'error')
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.places {
  .place-item {
    align-items: center;
    background-color: white;
    display: flex;
    padding: 0.75rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    color: var(--v-primary);

    &.active {
      background: var(--v-primary) !important;
      color: white !important;

      button.disable-events {
        background: white !important;
        color: var(--v-primary) !important;
      }
    }

    .place-item-icon {
      margin-right: 1rem;
    }
  }
}
</style>
<style lang="css" scoped>
.place-data-picker >>> .theme--light.v-picker__body {
  background-color: var(--box-bg-color);
}

.hour {
  opacity: 0.6;
}

.active.hour {
  opacity: 1;
}

.main {
  background: white;
  border-radius: 8px;
}

.hour-btn {
  max-width: 36px;
}

.schedule-procedure >>> .v-picker {
  border-radius: 1rem;
  width: 290px;
}
</style>
