<template>
  <div class="breadcrumbs">
    <div v-for="(btn, index) in buttons" :key="index" class="step">
      <v-btn
        :color="enabled(index) ? 'primary' : '#BBBBBB'"
        :dark="enabled(index)"
        class="mx-2 disable-events"
        fab
        elevation="0"
        :outlined="!enabled(index)"
        small
      >
        <v-icon :dark="enabled(index)">
          {{ btn.icon }}
        </v-icon>
      </v-btn>
      <div class="separator" v-if="index != buttons.length - 1"></div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Breadcrumbs',
  props: ['current'],
  methods: {
    enabled(index) {
      return this.current == index
    },
  },
  data() {
    return {
      layout: 'default',
      buttons: [
        { icon: 'mdi-file-document-outline' },
        { icon: 'mdi-calendar' },
        { icon: 'mdi-account-outline' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: right;
  .step {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .separator {
    width: 20px;
    height: 1px;
    background: #bbbbbb;
  }
}
// .summary {
//   background: var(--box-bg-color);
//   border-radius: 1.375rem;
//   display: flex;
//   font-size: 0.875rem;
//   min-height: 224px;
//   padding: 4px;

//   .summary-head {
//     display: flex;
//     flex-direction: row;
//     background-color: white;
//     height: 50px;
//     width: 100%;
//     border-radius: 1.375rem;
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//     text-align: center;
//     align-items: center;
//     font-size: 0.875rem;
//   }

//   .summary-body {
//     padding: 1rem;
//   }

//   .summary-head-title {
//     width: 100%;
//   }
// }
</style>
