<template>
  <div class="tip">
    <v-icon color="f4f4f8" class="mr-2">{{ icon }}</v-icon>
    <div class="content">
      <slot />
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Tip',
  props: {
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
  },
}
</script>

<style lang="css" scoped>
.tip {
  padding: 1rem;
  background: var(--box-bg-color);
  border-radius: 1.375rem;
  display: flex;
  font-weight: 700;
  font-size: 0.875rem;
  align-items: center;
}

.tip .v-icon {
  color: #262529;
}
</style>
