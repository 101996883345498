var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"schedule-procedure"},[_c('div',{staticClass:"main-content"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"12"}},[_c('div',{class:[
            'font-weight-bold',
            { 'text-center': _vm.$vuetify.breakpoint.smAndDown },
            'text-h6',
          ]},[_vm._v(" Excelente, nessa etapa vamos selecionar a data e o local! ")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"order-last order-sm-first",attrs:{"cols":"12","sm":"8"}},[_c('v-form',{ref:"form",staticClass:"box pa-8"},[(_vm.manyProductsAllowed)?_c('div',{staticClass:"form-content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Breadcrumbs',{staticClass:"justify-center justify-sm-end",attrs:{"current":1}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:[
                  'font-weight-bold',
                  'mb-2 mt-4',
                  { 'text-center': _vm.$vuetify.breakpoint.smAndDown },
                ]},[_vm._v(" Selecione os dias e turnos de sua preferência: ")])])],1),_vm._l((_vm.daysOfWeek),function(day,dayIndex){return _c('v-row',{key:dayIndex,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{staticClass:"ma-1 disable-events",attrs:{"color":_vm.dayAdded(day) ? 'primary' : 'default',"small":"","text":"","elevation":"0"}},[_vm._v(" "+_vm._s(day)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},_vm._l((_vm.shifts(day)),function(shift,shiftIndex){return _c('v-btn',{key:shiftIndex,class:[
                  { active: _vm.form.shifts.includes(`${day}-${shift}`) },
                  'ma-1',
                  'hour',
                ],attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":function($event){$event.preventDefault();return _vm.addShift(`${day}-${shift}`)}}},[_vm._v(" "+_vm._s(shift)+" ")])}),1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:[
                  'font-weight-bold',
                  'mb-2 mt-4',
                  { 'text-center': _vm.$vuetify.breakpoint.smAndDown },
                ]},[_vm._v(" Selecione um local de sua preferência: ")])])],1),_c('div',{staticClass:"places"},_vm._l((_vm.places),function(place,placeIndex){return _c('div',{key:placeIndex,class:[
                { active: _vm.form.places.includes(place.emp_cod) },
                'place-item mb-2',
              ],on:{"click":function($event){return _vm.togglePlace(place.emp_cod)}}},[_c('div',{staticClass:"place-item-icon"},[_c('v-btn',{staticClass:"disable-events",attrs:{"dark":"","color":"primary","x-small":"","fab":"","elevation":"0"}},[_c('v-icon',[_vm._v(" mdi-map-marker ")])],1)],1),_c('div',{staticClass:"place-item-body"},[_c('div',{staticClass:"place-item-title font-weight-bold"},[_vm._v(" "+_vm._s(place.emp_nomfan)+" ")]),_c('div',{staticClass:"place-item-subtile"},[_vm._v(_vm._s(place.emp_end))])])])}),0)],2):_c('div',{staticClass:"form-content"},[_c('v-row',[_c('v-col',{staticClass:"order-last order-sm-first",attrs:{"cols":"12","sm":"6"}},[_c('div',{class:[
                  'font-weight-bold',
                  'mb-2 mt-4',
                  { 'text-center': _vm.$vuetify.breakpoint.smAndDown },
                ]},[_vm._v(" Agendar por: ")]),_c('div',{staticClass:"d-flex justify-center justify-sm-start"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","outlined":_vm.form.kind != 'date',"elevation":"0"},on:{"click":function($event){_vm.form.kind = 'date'}}},[_vm._v(" Data ")]),_c('v-btn',{attrs:{"color":"primary","outlined":_vm.form.kind != 'place',"elevation":"0"},on:{"click":function($event){_vm.form.kind = 'place'}}},[_vm._v(" Unidade ")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('Breadcrumbs',{staticClass:"justify-center justify-sm-end",attrs:{"current":1}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.kind == 'date'),expression:"form.kind == 'date'"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:[
                  'font-weight-bold',
                  'mb-2 mt-4',
                  { 'text-center': _vm.$vuetify.breakpoint.smAndDown },
                ]},[_vm._v(" Selecione o dia: ")]),_c('v-date-picker',{attrs:{"width":"290","no-title":"","color":"primary","allowed-dates":_vm.allowedDates},on:{"update:picker-date":_vm.onChangeMonth},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}},[(_vm.getSchedulesLoading)?_c('div',{staticStyle:{"position":"absolute","top":"50%","right":"50%","bottom":"50%"}},[_c('v-row',{staticClass:"my-4",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"value":0}},_vm._l((_vm.availablePlaces),function(place,placeIndex){return _c('PlacePanel',{key:placeIndex,attrs:{"title":place.name,"subtitle":place.address}},_vm._l((_vm.findDoctors(place.id)),function(doctor,doctorIndex){return _c('div',{key:doctorIndex},[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm._f("DRname")(doctor.name))+" ")]),_vm._l((_vm.findHours(
                        doctor.id,
                        place.id
                      )),function(hour,hourIndex){return _c('v-btn',{key:hourIndex,staticClass:"ma-1 hour-btn",class:[{ active: _vm.form.hour_id == hour.id }, 'hour'],attrs:{"small":"","elevation":"0","dark":"","color":"primary"},on:{"click":function($event){$event.preventDefault();_vm.form.hour_id = hour.id}}},[_vm._v(" "+_vm._s(_vm._f("hour")(hour.time))+" ")])})],2)}),0)}),1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.kind == 'place'),expression:"form.kind == 'place'"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:[
                  'font-weight-bold',
                  'mb-2 mt-4',
                  { 'text-center': _vm.$vuetify.breakpoint.smAndDown },
                ]},[_vm._v(" Selecione a unidade: ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"value":0}},_vm._l((_vm.schedulePlaces),function(place,placeIndex){return _c('PlacePanel',{key:placeIndex,attrs:{"title":place.name,"subtitle":place.address}},[_c('PlacePanelSchedule',{attrs:{"hour_id":_vm.form.hour_id,"place":place},on:{"updateHour":hour => (_vm.form.hour_id = hour)}})],1)}),1)],1)],1)],1),_c('div',{staticClass:"form-footer mt-6"},[_c('v-row',[_c('v-col',{staticClass:"text-right"},[(!_vm.doctorParam)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","outlined":"","elevation":"0"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('schedule/step', 1)}}},[_vm._v(" Voltar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","elevation":"0","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.commit.apply(null, arguments)}}},[_vm._v(" Continuar ")])],1)],1)],1)])],1),_c('v-col',[(_vm.tip)?_c('Tip',{staticClass:"mb-4"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.tip)}})]):_vm._e(),_c('Summary')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }