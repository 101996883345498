<template lang="html">
  <div class="schedule-procedure">
    <Recaptcha ref="recaptcha" @verify="recaptchaCallback" />
    <ConfirmationDialog ref="confirmationDialog" />
    <div class="main-content">
      <v-row no-gutters>
        <v-col cols="12" class="pa-3">
          <div
            :class="[
              'font-weight-bold',
              { 'text-center': $vuetify.breakpoint.smAndDown },
              'text-h6',
            ]"
          >
            Muito bem, chegou a hora de confirmar sua solicitação
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="order-last order-sm-first" sm="8">
          <v-form ref="form" class="box pa-8" v-model="form.valid">
            <v-row class="mb-4">
              <v-col cols="12" sm="6" class="order-last order-sm-first">
                <div
                  v-if="
                    options.exam_schedule_enabled &&
                    options.appointment_schedule_enabled
                  "
                >
                  <div
                    :class="[
                      'font-weight-bold',
                      'mb-2 mt-4',
                      { 'text-center': $vuetify.breakpoint.smAndDown },
                    ]"
                  >
                    Informações do paciente:
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <Breadcrumbs
                  :current="2"
                  class="justify-center justify-sm-end"
                />
              </v-col>
            </v-row>
            <v-form
              @submit.prevent="signin"
              v-show="!showPatientForm"
              ref="signinForm"
              v-model="signinForm.valid"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    label="CPF"
                    filled
                    outlined
                    dense
                    background-color="#fff"
                    :disabled="disableCPF"
                    v-mask.raw="'###.###.###-##'"
                    v-model="credentials.cpf"
                    :rules="$rules.required"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn
                    type="submit"
                    color="primary"
                    elevation="0"
                    :loading="loading"
                  >
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div class="form-content" v-show="showPatientForm">
              <v-text-field
                label="CPF"
                :rules="$rules.required"
                filled
                outlined
                dense
                background-color="#fff"
                v-model="form.cpf"
                :disabled="disableCPF"
                v-mask.raw="'###.###.###-##'"
              ></v-text-field>
              <v-text-field
                v-model="form.name"
                :rules="$rules.required"
                outlined
                label="Nome"
                filled
                dense
                background-color="#fff"
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                :rules="$rules.email(true)"
                outlined
                label="E-mail"
                filled
                dense
                background-color="#fff"
              ></v-text-field>
              <v-row class="my-0">
                <v-col class="py-0" cols="12" sm="6">
                  <v-text-field
                    v-model="form.birthday"
                    :rules="$rules.required"
                    v-mask.raw="'##/##/####'"
                    outlined
                    label="Data de Nascimento"
                    filled
                    dense
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <v-text-field
                    outlined
                    label="Peso"
                    v-mask.raw="'############'"
                    v-model="form.weight"
                    filled
                    dense
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col class="py-0" cols="12" sm="6">
                  <v-text-field
                    v-model="form.phone1"
                    :rules="$rules.required"
                    outlined
                    v-mask.raw="'(##) ##### ####'"
                    label="Contato"
                    filled
                    dense
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" sm="6">
                  <v-text-field
                    v-model="form.phone2"
                    outlined
                    v-mask.raw="'(##) ##### ####'"
                    label="Contato"
                    filled
                    dense
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-divider class="mt-4 mb-6"></v-divider>
            <div class="form-content">
              <v-textarea
                name="obs"
                label="Deseja adicionar alguma anotação?"
                v-model="form.obs"
                filled
                dense
                background-color="#fff"
              ></v-textarea>
            </div>

            <div class="form-footer mt-6">
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="primary"
                    outlined
                    elevation="0"
                    class="mr-4"
                    @click.prevent="$store.commit('schedule/step', 2)"
                  >
                    Voltar
                  </v-btn>
                  <v-btn
                    color="primary"
                    elevation="0"
                    @click.prevent="commit"
                    :loading="loading"
                  >
                    Confirmar
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-col>
        <v-col>
          <Tip v-if="tip" class="mb-4">
            <div v-html="tip"></div>
          </Tip>
          <Summary />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Tip from '@/views/schedule/widgets/tip'
import { isEmpty, sortBy } from 'lodash'
import Api from '@/resources/NajaSocialApi'
import Summary from '@/views/schedule/widgets/summary'
import Breadcrumbs from '@/views/schedule/widgets/breadcrumbs'
import ConfirmationDialog from '@/views/schedule/steps/confirmation'
import Recaptcha from '@/components/Recaptcha'

export default {
  name: 'SchedulePatientStep',
  components: { Tip, Summary, Breadcrumbs, Recaptcha, ConfirmationDialog },
  data() {
    return {
      loading: false,
      disableCPF: false,
      showPatientForm: false,
      credentialsRules: {
        cpf: [v => !!v || 'Obrigatório'],
      },
      credentials: {
        cpf: null,
      },
      rules: {
        cpf: [v => !!v || 'Obrigatório'],
        name: [v => !!v || 'Obrigatório'],
        birthday: [v => !!v || 'Obrigatório'],
        email: [v => !!v || 'Obrigatório'],
        phone1: [v => !!v || 'Obrigatório'],
      },
      signinForm: {
        valid: true,
      },
      form: {
        valid: true,
        cpf: null,
        name: null,
        birthday: null,
        email: null,
        phone1: null,
        phone2: null,
      },
    }
  },

  mounted() {
    // this._loadData()
  },

  computed: {
    ...mapState({
      options: state => state.schedule.options,
      group: state => state.schedule.group,
    }),

    ...mapGetters({
      products: 'schedule/selectedProducts',
      branches: 'schedule/selectedBranches',
      shifts: 'schedule/selectedShifts',
      hour: 'schedule/hour',
      groupLabel: 'schedule/groupLabel',
      covenant: 'schedule/covenant',
      plan: 'schedule/plan',
      manyProductsAllowed: 'schedule/manyProductsAllowed',
    }),

    tip() {
      let tip = null
      if (this.group == 'exams') {
        tip = this.options.exam_schedule_patient_screen_tip
      }
      if (this.group == 'appointments') {
        tip = this.options.appointment_schedule_patient_screen_tip
      }

      return tip
    },

    place() {
      return isEmpty(this.hour) ? {} : this.hour.place()
    },

    doctor() {
      return isEmpty(this.hour) ? {} : this.hour.doctor()
    },

    date() {
      return this.$moment(this.hour.time, 'YYYYMMDD HH:mm').format(
        'DD/MM/YYYY [às] HH:mm'
      )
    },

    examTitle() {
      return this.products.length > 1
        ? 'Exames selecionados'
        : 'Exame selecionado'
    },

    appointment() {
      return this.$store.getters['schedule/selectedAppointment'] || {}
    },
  },

  methods: {
    saveSchedule(recaptchaToken, onSuccess = null) {
      this.loading = true
      const form = this.$store.state.schedule.form

      let productCode = null
      if (form.exam.group == 'exams') {
        productCode = form.exam.products[0]
      }
      if (form.exam.group == 'appointments') {
        productCode =
          this.$store.getters['schedule/selectedAppointment']
            .ProcedimentoDefault.Codigo
      }
      const time = this.$moment(this.hour.time, 'YYYYMMDD HH:mm')
      const birthday = this.$moment(form.patient.birthday, 'DD/MM/YYYY').format(
        'YYYYMMDD'
      )

      const schedule = {
        aex_emp_cod: this.hour.place_id,
        aex_rag_cod: this.hour.rag_cod,
        aex_con_cod: form.exam.covenant_id,
        aex_nomepac: form.patient.name,
        aex_dtnasc: birthday,
        aex_plc_isn: form.exam.plan_id,
        agi_isn: this.hour.agi_isn,
        fila_tipo: '',
        aex_obsage: '',
        aex_numfone1: form.patient.phone1,
        aex_numfone2: form.patient.phone2,
        aex_obs: form.patient.obs,
        aex_email: form.patient.email,
        aex_data: time.format('YYYYMMDD'),
        horario: time.format('HHmm'),
        aex_hora: time.format('HHmm'),
        pro_cod: productCode,
        anx_extensao: '',
        aex_cpf: form.patient.cpf.replace(/\D/g, ''),
        pesoPac: form.patient.weight,
      }

      Api.createSchedule(schedule, recaptchaToken)
        .then(() => {
          this.$toast.show('Agendamento salvo com sucesso.', 'success')
          this.done = true

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        })
        .catch(error => {
          if (error.response.status == 500) {
            this.$toast.show('Erro ao salvar agendamento', 'error')
          } else if (error.response.status == 400) {
            if (error.response.data.warn != null) {
              this.$toast.show(error.response.data.warn)
              this.done = true
              if (typeof onSuccess === 'function') {
                onSuccess()
              }
              this.$store.commit('schedule/form', { obs: this.obs })
            }

            if (error.response.data.error != null) {
              this.$toast.show(error.response.data.error, 'error')
            }
          } else {
            this.$toast.show(error.response.data.error, 'error')
          }

          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },

    async saveTask(recaptchaToken, onSuccess) {
      this.loading = true
      const form = this.$store.state.schedule.form
      let text = `AGENDAMENTO ONLINE | MÚLTIPLOS EXAMES\n\nPaciente\n${form.patient.name}\n\n`
      text += `Contatos\n${form.patient.phone1}`
      if (!_.isEmpty(form.patient.phone2)) {
        text += ` / ${form.patient.phone2}\n`
      }
      if (form.exam.group == 'exams') {
        text += `\nExames selecionados\n`
        form.exam.products.forEach(product => {
          text += `${product}\n`
        })
      }

      if (form.exam.group == 'appointments') {
        text += `\nEspecialidade selecionada\n${this.appointment.NomeRecurso}\n`
      }

      text += `\nUnidades de preferência\n`
      this.branches.forEach(branch => {
        text += `${branch.emp_end}\n`
      })
      text += `\nDias e turnos de preferência\n`
      Object.keys(this.shifts).forEach(shiftKey => {
        text += `${shiftKey}`
        if (this.shifts[shiftKey].length > 0) {
          text += ' - ' + this.shifts[shiftKey].join(', ')
        }
        text += `\n`
      })

      text += `\nConvênio\n${this.covenant.con_nomefant}\n\n`
      text += `Plano\n${this.plan.plc_nome}\n\n`
      text += `Informação adicional do agendamento\n${this.obs}\n\n`

      const limiteDate =
        this.$store.state.schedule.options.exam_schedule_limit_days == null
          ? this.$moment().format('YYYYMMDD')
          : this.$moment()
              .add(
                parseInt(
                  this.$store.state.schedule.options.exam_schedule_limit_days
                ),
                'D'
              )
              .format('YYYYMMDD')
      const taskUsers =
        this.$store.state.schedule.options.exam_schedule_user_code
          .replaceAll('.', ',')
          .split(',')

      const requests = taskUsers.map(async taskUser => {
        try {
          const task = {
            usuResp: taskUser,
            dataLimite: limiteDate,
            texto: text,
            fatoGerador: 0,
          }
          await Api.createUserTask(task, recaptchaToken)
          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } catch (e) {
          return false
        }
      })

      const results = await Promise.all(requests)
      if (results.includes(false)) {
        this.$toast.show('Erro ao salvar agendamento', 'error')
      } else {
        this.$toast.show('Agendamento salvo com sucesso.', 'success')
        this.done = true

        this.$store.commit('schedule/form', { obs: this.obs })
      }

      this.loading = false
    },

    commit() {
      if (!this.showPatientForm) {
        this.$toast.show('Informe os dados do paciente', 'error')
        return
      }

      const valid =
        this.$refs.signinForm.validate() && this.$refs.form.validate()

      if (valid) {
        this.$refs.recaptcha.execute()
      }
    },

    recaptchaCallback(token) {
      this.loading = true
      this.$store.commit('schedule/form', { patient: this.form })

      const afterSave = () => {
        // this.$store.commit('schedule/step', 4)
        this.$refs.confirmationDialog.show()
        this.loading = false
      }

      if (this.manyProductsAllowed) {
        this.saveTask(token, afterSave)
      } else {
        this.saveSchedule(token, afterSave)
      }
    },

    _setPatient(patient) {
      const phoneTypes = ['Celular', 'Residencial', 'Comercial']
      const contacts = sortBy(patient.contatos, 'tipo.descricao', phoneTypes)
      const phones = []

      contacts.forEach(contact => {
        if (phoneTypes.includes(contact.tipo.descricao)) {
          phones.push(`${contact.ddd}${contact.numero}`)
        }
      })

      const birthday =
        patient.data_nascimento == null
          ? null
          : this.$moment(patient.data_nascimento, 'YYYYMMDD').format(
              'DD/MM/YYYY'
            )
      this.form = {
        valid: null,
        cpf: patient.cpf,
        name: patient.nome,
        birthday: birthday,
        email: patient.email,
        phone1: phones[0] || '',
        phone2: phones[1] || '',
        obs: this.form.obs,
      }

      this.disableCPF = !isEmpty(patient.cpf)
      this.patientLoaded = true
    },

    signin() {
      this.$refs.signinForm.validate()

      if (this.signinForm.valid) {
        this.loading = true
        this.signInSubmitted = true

        const payload = {
          cpf: this.credentials.cpf.replace(/[^0-9]/g, ''),
        }

        Api.patients(payload)
          .then(response => {
            const patient = response.data.patient[0]
            if (patient) {
              this._setPatient(response.data.patient[0])
            } else {
              this.form.cpf = this.credentials.cpf
              this.patientLoaded = true
            }

            this.showPatientForm = true
          })
          .catch(error => {
            this.$toast.show('Erro ao buscar usuário', 'error')
            throw error
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="css" scoped>
.main {
  background: white;
  border-radius: 8px;
}

.form-content {
  max-width: 360px;
}
</style>
